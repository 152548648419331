import React from "react";

function GlossaryScopePopup({ handleApplyGlossary, handlePopoverVisibility }) {
  return (
    <div className="absolute bottom-10 right-1">
      <div
        onClick={() => {
          handlePopoverVisibility("close");
        }}
        className=" z-[1] fixed  w-full h-full top-0 left-0"
      ></div>
      <div
        style={{
          boxShadow: "0px 4px 20px 0px rgba(0, 0, 0, 0.25)",
        }}
        className="p-3 rounded flex w-36 flex-col text-[12px] items-start gap-2 relative z-[10] bg-white"
      >
        <div
          style={{ borderBottom: "1px solid #979797" }}
          className="text-[#979797] w-full "
        >
          Choose any 1
        </div>
        <div className="flex flex-col text-[#000] gap-1">
          <div
            className="cursor-pointer"
            onClick={() => {
              handleApplyGlossary("Book");
            }}
          >
            Book
          </div>
          <div
            className="cursor-pointer"
            onClick={() => {
              handleApplyGlossary("Author");
            }}
          >
            Author
          </div>
          <div
            className="cursor-pointer"
            onClick={() => {
              handleApplyGlossary("Genre");
            }}
          >
            Genre
          </div>
          <div
            className="cursor-pointer"
            onClick={() => {
              handleApplyGlossary("Language");
            }}
          >
            Language
          </div>
        </div>
      </div>
    </div>
  );
}

export default GlossaryScopePopup;
