import React, { useCallback, useEffect, useState } from "react";

import CloseIcon from "@mui/icons-material/Close";
import AddToGlossary from "../AddToGlossary/AddToGlossary";
import { ReactComponent as GlossaryIcon } from "../../assets/glossary.svg";
import { ReactComponent as AddGlossaryIcon } from "../../assets/addGlossaryIcon.svg";
import { ReactComponent as AddGlossaryWhiteIcon } from "../../assets/addGlossaryWhiteIcon.svg";

import { ReactComponent as GlossaryViewIcon } from "../../assets/glossaryViewIcon.svg";
import { ReactComponent as ChevronRight } from "../../assets/chevronRight.svg";
import { ReactComponent as GlossaryStarIcon } from "../../assets/glossaryStarIcon.svg";
import { toast } from "react-toastify";
import {
  applySelectedGlossary,
  getGlossaryItem,
  updateGlossarytoTheBook,
} from "../../utils/glossaryUtils";
import GlossaryAccordion from "../GlossaryAccordion/GlossaryAccordion";
import { Button, TextField } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import { ReactComponent as GlossarySearchIcon } from "../../assets/glossarySearchIcon.svg";
import debounce from "lodash.debounce";
import GlossaryWarningModal from "../glosssaryWarningModal/GlossaryWarningModal";
import store from "../../App/store";
import { styled, Tooltip } from "@mui/material";
import GlossarySuggestion from "../GlossarySuggestions/GlossarySuggestion";
import { useDispatch, useSelector } from "react-redux";
import { faCropSimple } from "@fortawesome/free-solid-svg-icons";
import GlossaryScopePopup from "../glossaryScopePopup/GlossaryScopePopup";
import { setGlossaryList } from "../../App/editor/editor.actions";

function Glossary({
  chapter,
  selectedGlossary,
  setSelectedGlossary,
  // appliedGlossaries,
  // setAppliedGlossaries,
  applyGlossary,
  setAfterHandledUnsavedChangesAction,
  fetchGlossarySuggestionsData,
  textToAddInGlossary,
  setTextToAddInGlossary,
  sourceEditor,
  handleChapterChange,
  fetchTranslatedChapterPending,
}) {
  const [openAddToGlossarySection, setOpenAddToGlossarySection] = useState(
    textToAddInGlossary ? true : false
  );
  const [englishWord, setEnglishWord] = useState("");
  const [bookGlossaryData, setBookGlossaryData] = useState([]);
  const [selectedEnglishWord, setSelectedEnglishWord] = useState("");
  const [openedGlossaryItem, setOpenedGlossaryItem] = useState("");
  const [loading, setLoading] = useState(false);
  const [glossryLoadingState, setGlossryLoadingState] = useState(false);
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const [glossarySearchInput, setGlossarySearchInput] = useState("");
  const [glossaryFilteredData, setGlossaryFilteredData] = useState([]);
  const [sectionType, setSectionType] = useState("glossary");
  const { glossarySuggestionData } = useSelector((state) => state.editor);
  const [glossarySuggestionFilteredData, setGlossarySuggestionFilteredData] =
    useState("");
  const dispatch = useDispatch();
  // const [showInputFeildError, setShowInputFeildError] = useState(false);

  // const handleInputChange = (e) => {
  //   console.log(e.target.value, "value");
  //   setShowInputFeildError(false);
  //   setEnglishWord(e.target.value);
  // };
  useEffect(() => {
    if (textToAddInGlossary) {
      setOpenAddToGlossarySection(true);
    }
  }, [textToAddInGlossary]);

  const getBookGlossaryData = async () => {
    if (chapter?.translation_id) {
      setGlossryLoadingState(true);
      const glossaryItems = await getGlossaryItem(chapter?.translation_id);
      glossaryItems?.sort((a, b) => {
        if (a.english_word < b.english_word) return -1;
        if (a.english_word > b.english_word) return 1;
        return 0;
      });
      setBookGlossaryData(glossaryItems);
      dispatch(setGlossaryList(glossaryItems ?? []));
      setGlossryLoadingState(false);
    }
  };

  useEffect(() => {
    getBookGlossaryData();
  }, [chapter]);

  const handleCloseGlossary = () => {
    const glossaryButton = document.querySelector(
      '[data-cke-tooltip-text="Glossary"]'
    );
    console.log("drcalling",glossaryButton)
    glossaryButton?.click();
  };

  const handleApplyGlossary = async (scope) => {
    if (store.getState().editor.isGlossaryApplying.status) {
      toast(`Please wait for the previous glossary process to complete.`, {
        type: "warning",
        autoClose: 3000,
      });
      return;
    }
    if (!selectedGlossary.variant) {
      toast(`Please select any variant to apply`, {
        type: "warning",
        autoClose: 3000,
      });
      return;
    }

    let selectedGlossaryItem = bookGlossaryData.find(
      (glossary) => glossary?.english_word === selectedGlossary?.englishWord
    );

    if (!selectedGlossaryItem) return;

    // if (
    //   appliedGlossaries?.find(
    //     (glossary) => glossary?.variant === selectedGlossary?.variant
    //   )
    // ) {
    //   return;
    // }

    // setIsPopoverOpen(false);
    // setOpenedGlossaryItem("");
    // setAppliedGlossaries((prev) => [...[prev], selectedGlossary]);/
    const glossaryPayload = {
      author: chapter.book_info.author_name,
      language: "dutch",
      genre: chapter.book_info.genre,
      english_word: selectedGlossaryItem.english_word,
      dutch_word_array: selectedGlossaryItem.dutch_word_array,
      glossary_item_scope: scope,
      application_scope: "book_level",
      chapter_numbers: [],
      is_active: true,
      is_part_of_collection: false,
      application_history: null,
    };
    setSelectedGlossary({ englishWord: "", variant: "" });
    const isTranslatedContentEdited =
      store?.getState()?.editor?.isTranslationContentEdited;
    if (isTranslatedContentEdited) {
      setAfterHandledUnsavedChangesAction({
        actionName: "applyGlossary",
        actionData: {
          glossaryPayload,
          applyType: "glossary",
          selectedGlossaryItem,
          selectedGlossary,
          chapterData: chapter,
        },
      });
      // handleCloseAddToGlossarySection()
      return;
    }
    // handleCloseAddToGlossarySection()
    setLoading(true);
    await applyGlossary(
      glossaryPayload,
      "glossary",
      chapter,
      selectedGlossaryItem,
      selectedGlossary
    );
    setLoading(false);
  };

  const handlePopoverVisibility = (type) => {
    const glossaryElem = document.querySelector(".glossaryContainer");
    if (type === "close") {
      setIsPopoverOpen(false);
      glossaryElem.style.zIndex = "0";
      return;
    }

    if (isPopoverOpen) {
      glossaryElem.style.zIndex = "0";
    } else {
      glossaryElem.style.zIndex = "10";
    }

    setIsPopoverOpen(!isPopoverOpen);
  };

  useEffect(() => {
    setGlossaryFilteredData(bookGlossaryData);
    setGlossarySuggestionFilteredData(glossarySuggestionData);
    setGlossarySearchInput("");
  }, [bookGlossaryData, glossarySuggestionData, sectionType]);

  const debouncedGlossarySearch = useCallback(
    debounce((searchInput) => {
      if (searchInput) {
        const lowercasedSearchTerm = searchInput.trim().toLowerCase();

        if (sectionType === "glossary") {
          const newFilteredData = bookGlossaryData.filter((glossary) => {
            if (
              glossary.english_word.toLowerCase().includes(lowercasedSearchTerm)
            )
              return true;

            let isVariantFound = false;
            glossary.dutch_word_array.forEach((variant) => {
              if (
                variant.replacement_dutch_word
                  .toLowerCase()
                  .includes(lowercasedSearchTerm)
              )
                isVariantFound = true;
            });

            return isVariantFound;
          });
          setGlossaryFilteredData(newFilteredData);
        }

        if (sectionType === "suggestions") {
          const newFilteredData = glossarySuggestionData.filter((glossary) => {
            if (
              glossary.english_word.toLowerCase().includes(lowercasedSearchTerm)
            )
              return true;
            if (
              glossary.dutch_word_array[
                glossary.applied_index
              ].replacement_dutch_word
                .toLowerCase()
                .includes(lowercasedSearchTerm)
            )
              return true;
          });
          setGlossarySuggestionFilteredData(newFilteredData);
        }
      } else {
        sectionType === "glossary" && setGlossaryFilteredData(bookGlossaryData);
        sectionType === "suggestions" &&
          setGlossarySuggestionFilteredData(glossarySuggestionData);
      }
    }, 500),
    [bookGlossaryData, glossarySuggestionFilteredData, sectionType]
  );

  const handleSearchChange = (e) => {
    const value = e.target.value;
    setGlossarySearchInput(value);
    debouncedGlossarySearch(value);
  };

  const handleCloseAddToGlossarySection = () => {
    setOpenAddToGlossarySection(false);
    setSelectedEnglishWord("");
    getBookGlossaryData();
    setGlossarySearchInput("");
    setTextToAddInGlossary("");
  };

  return (
    <div
      style={{ height: "calc(100dvh - 133px - 71px)" }}
      className="w-[576px] shrink-0 mt-[150px] rounded-md  relative ml-4 "
    >
      {!openAddToGlossarySection && (
        <div
          style={{ height: "calc(100dvh - 133px - 71px)" }}
          className={`w-[576px] glossaryContainer shrink-0 top-[100px] left-4  fixed py-2 gap-2 flex  ${
            sectionType === "glossary" ? "pb-[67.46px]" : "pb-[10px]"
          }  flex-col px-3 bg-[#FFFFFF] my-12  rounded-md`}
        >
          <div className="flex justify-between">
            <div className="flex items-center gap-4 ">
              <div
                onClick={() => setSectionType("glossary")}
                className={`flex cursor-pointer text-sm  items-center ${
                  sectionType === "glossary" && "bg-[#E8E8F2]"
                } py-[2px] px-2 rounded-lg gap-1`}
              >
                <GlossaryIcon /> <span>Glossary</span>
              </div>
              <div
                onClick={() => setSectionType("suggestions")}
                className={`flex cursor-pointer text-sm items-center ${
                  sectionType === "suggestions" && "bg-[#E8E8F2]"
                }  px-2 rounded-lg py-[2px] gap-1`}
              >
                <GlossaryStarIcon />{" "}
                <span>Suggested ({glossarySuggestionData.length})</span>
              </div>
            </div>
            <div className="flex items-center gap-3">
              <div
                style={{ border: "1px solid #A6A6A6" }}
                className="flex text-[10px] w-[211px] px-2 py-[6px] items-center rounded gap-2"
              >
                <GlossarySearchIcon className="w-4 h-4" />
                <input
                  value={glossarySearchInput}
                  onChange={handleSearchChange}
                  placeholder="Search..."
                  type="text"
                  maxLength={100}
                  className="border-none w-full outline-none"
                />
              </div>
              {/* <Button
                variant="contained"
                className="flex rounded-[4px]  text-white relative text-[12px] py-[5px] px-3 gap-2"
                onClick={() => {
                  if (glossarySearchInput) {
                    let updatedSelectedEnglishWord = "";
                    glossaryFilteredData.forEach((glossary) => {
                      if (
                        glossary.english_word.toLowerCase() ===
                        glossarySearchInput?.trim()?.toLowerCase()
                      )
                        updatedSelectedEnglishWord = glossary.english_word;
                    });
                    if (updatedSelectedEnglishWord)
                      setSelectedEnglishWord(updatedSelectedEnglishWord);
                  }

                  setOpenAddToGlossarySection(true);
                  setOpenedGlossaryItem("");
                }}
              >
                <div className="flex items-center gap-2">
                  <AddGlossaryWhiteIcon className="text-white" />
                  Add to glossary
                </div>
              </Button> */}
              <CloseIcon
                className="cursor-pointer w-5 h-5"
                onClick={handleCloseGlossary}
              />
            </div>
          </div>
          {/* <div className="flex flex-col">
          <span className="text-[#5F6368] text-[10px]">
            Sentence / Word
          </span>
          <TextField
            value={englishWord}
            inputProps={{
              autoComplete: "off",
            }}
            error={showInputFeildError}
            helperText={
              showInputFeildError && "Please enter english word/sentence first"
            }
            id="standard-basic"
            variant="standard"
            sx={{
              "& .css-1nhz38b-MuiInputBase-input-MuiInput-input": {
                fontSize: "14px",
              },
            }}
            maxLength={100}
            onChange={handleInputChange}
            placeholder="Enter english word/sentence here .."
            className="text-[#000] w-1/2 mb-2 py-1 rounded-sm  border-x-0 outline-none border-t-0 text-[12px]"
          />
        </div> */}
          <div
            style={{ borderBottom: "1px solid #BFBFBF" }}
            className="flex items-center pb-2 mt-2  gap-5 w-full"
          >
            <div
              className={`flex  w-1/2 ${
                sectionType === "glossary"
                  ? "bg-[#F1F3F4] justify-between"
                  : "bg-[#FFE8B2] justify-center"
              } items-center py-2 pr-2 pl-4`}
            >
              <span className="text-sm text-[#1E1C80]">English Glossary </span>
              {/* {sectionType === "glossary" && (
              <AddGlossaryIcon
                onClick={() => {
                  // if (!englishWord) {
                  //   setShowInputFeildError(true);
                  //   return;
                  // }

                  if (glossarySearchInput) {
                    let updatedSelectedEnglishWord = "";
                    glossaryFilteredData.forEach((glossary) => {
                      if (
                        glossary.english_word.toLowerCase() ===
                        glossarySearchInput?.trim()?.toLowerCase()
                      )
                        updatedSelectedEnglishWord = glossary.english_word;
                    });
                    if (updatedSelectedEnglishWord)
                      setSelectedEnglishWord(updatedSelectedEnglishWord);
                  }

                  setOpenAddToGlossarySection(true);
                  setOpenedGlossaryItem("");
                }}
                className="cursor-pointer"
              />
            )} */}
            </div>
            <div
              className={`flex  ${
                sectionType === "glossary"
                  ? "bg-[#F1F3F4] justify-between"
                  : "bg-[#FFE8B2] justify-center"
              } text-sm text-[#1E1C80] w-1/2  items-center py-2 pr-2 pl-4`}
            >
              Dutch Variants
            </div>
          </div>
          {sectionType === "glossary" ? (
            <div
              style={{ height: "calc(100dvh - 133px - 180px)" }}
              className=" overflow-y-scroll hideSourceScrollBar"
            >
              {
                // bookGlossaryData.length > 0 ?
                glossryLoadingState && !glossaryFilteredData?.length ? (
                  <div className="w-full text-center text-[#5F6368] text-sm pt-10">
                    Loading...
                  </div>
                ) : glossarySearchInput && !glossaryFilteredData?.length ? (
                  <div className="w-full justify-center items-center flex-col flex pt-4 gap-2">
                    <p className="text-[#5F6368] text-  [10px] m-0">Opps!</p>

                    <p className="text-[#CF1322] text-[14px] m-0">
                      Searched glossary does not exist
                    </p>

                    <Button
                      onClick={() => {
                        // if (!englishWord) {
                        //   setShowInputFeildError(true);
                        //   return;
                        // }
                        // setOpenAddToGlossarySection(true);
                      }}
                      variant="outlined"
                      className="flex rounded-[4px] text-[12px] py-[6px] px-3 gap-2"
                    >
                      Add it to Glossary <AddGlossaryIcon />
                    </Button>
                  </div>
                ) : !glossaryFilteredData?.length ? (
                  <div className="flex flex-col items-center pt-2 gap-1">
                    <div className="flex items-center pb-2  gap-5 w-full">
                      <div className=" w-1/2 text-sm text-center text-[#000000] bg-[#F1F3F4] p-1">
                        NO GLOSSARY EXIST
                      </div>
                      <div className="w-1/2 text-sm  text-[#000000]  bg-[#F1F3F4] text-center p-1">
                        NO VARIANT EXIST
                      </div>
                    </div>
                    <Button
                      onClick={() => {
                        // setOpenAddToGlossarySection(true);
                      }}
                      variant="outlined"
                      className="flex rounded-[4px]  text-[12px] py-[6px] px-3 gap-2"
                    >
                      Add Glossary and Variants
                    </Button>
                  </div>
                ) : (
                  glossaryFilteredData.map((glossaryItem) => {
                    return (
                      <GlossaryAccordion
                        key={glossaryItem?.english_word}
                        chapter={chapter}
                        glossaryItem={glossaryItem}
                        // englishWord={englishWord}
                        getBookGlossaryData={getBookGlossaryData}
                        setSelectedEnglishWord={setSelectedEnglishWord}
                        setOpenAddToGlossarySection={
                          setOpenAddToGlossarySection
                        }
                        setOpenedGlossaryItem={setOpenedGlossaryItem}
                        openedGlossaryItem={openedGlossaryItem}
                        setSelectedGlossary={setSelectedGlossary}
                        selectedGlossary={selectedGlossary}
                        // setShowInputFeildError={setShowInputFeildError}
                      />
                    );
                  })
                )
              }
            </div>
          ) : glossarySearchInput && !glossarySuggestionFilteredData.length ? (
            <div className="w-full justify-center items-center flex-col flex pt-4 gap-2">
              <p className="text-[#5F6368] text-  [10px] m-0">Opps!</p>

              <p className="text-[#CF1322] text-[14px] m-0">
                Searched glossary suggestion does not exist
              </p>
            </div>
          ) : (
            <GlossarySuggestion
              applyGlossary={applyGlossary}
              glossarySuggestionData={glossarySuggestionFilteredData}
              chapter={chapter}
              fetchGlossarySuggestionsData={fetchGlossarySuggestionsData}
              setAfterHandledUnsavedChangesAction={
                setAfterHandledUnsavedChangesAction
              }
            />
          )}
          {sectionType === "glossary" && (
            <div
              style={{ borderTop: "1px solid #3B37FF" }}
              className="footer py-2 flex justify-end right-0 mx-2 gap-4  absolute bottom-0 w-[98%] "
            >
              <Button
                onClick={handleCloseGlossary}
                className="rounded-[4px] text-[12px] py-[6px] px-3 gap-2"
                variant="outlined"
              >
                Cancel
              </Button>
              {/* <PopupState variant="popover" popupId="demo-popup-popover">
            {(popupState) => (  */}
              <div className="relative">
                <Button
                  disabled={selectedGlossary.variant ? false : true}
                  onClick={() => {
                    if (!selectedGlossary.variant) {
                      toast(`Please select any variant to apply`, {
                        type: "warning",
                        autoClose: 3000,
                      });
                      return;
                    }
                    // handleApplyGlossary("Book");
                    handlePopoverVisibility();
                  }}
                  variant="contained"
                  className="flex rounded-[4px] relative text-[12px] py-[6px] px-3 gap-2"
                >
                  {/* {isLoading ? (
                <CircularProgress
                  size={24}
                  sx={{ width: "24px", height: "24px" }}
                  className="text-white mx-16 w-6 h-6"
                />
              ) : */}

                  <div className="flex items-center">
                    Apply your section to
                    <ChevronRight />
                  </div>
                </Button>
                {isPopoverOpen && (
                  <GlossaryScopePopup
                    handleApplyGlossary={handleApplyGlossary}
                    handlePopoverVisibility={handlePopoverVisibility}
                  />
                )}
              </div>
              {/* )} */}
              {/* </PopupState> } */}
            </div>
          )}

          {/* {openAddToGlossarySection && (
          <AddToGlossary
            onClose={() => {
              setOpenAddToGlossarySection(false);
              setSelectedEnglishWord("");
              getBookGlossaryData();
              setGlossarySearchInput("");
            }}
            // englishWord={englishWord}
            glossarySearchInput={glossarySearchInput}
            chapter={chapter}
            glossaryData={bookGlossaryData}
            selectedEnglishWord={selectedEnglishWord}
            setGlossarySearchInput={setGlossarySearchInput}
          />
        )} */}
        </div>
      )}
      {openAddToGlossarySection && (
        <AddToGlossary
          onClose={handleCloseAddToGlossarySection}
          glossarySearchInput={glossarySearchInput}
          highlightedEnglish={textToAddInGlossary}
          chapter={chapter}
          glossaryData={bookGlossaryData}
          selectedEnglishWord={selectedEnglishWord}
          setGlossarySearchInput={setGlossarySearchInput}
          handleCloseGlossary={handleCloseGlossary}
          getBookGlossaryData={getBookGlossaryData}
          handleApplyGlossary={handleApplyGlossary}
          handlePopoverVisibility={handlePopoverVisibility}
          isPopoverOpen={isPopoverOpen}
          setSelectedGlossary={setSelectedGlossary}
          selectedGlossary={selectedGlossary}
          setSelectedEnglishWord={setSelectedEnglishWord}
          sourceEditor={sourceEditor}
          handleChapterChange={handleChapterChange}
          fetchTranslatedChapterPending={fetchTranslatedChapterPending}
        />
      )}
    </div>
  );
}

export default Glossary;
