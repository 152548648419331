import React, { useCallback, useEffect, useState } from "react";
import "./AddToGlossary.scss";

import CloseIcon from "@mui/icons-material/Close";
import { ReactComponent as GlossaryIcon } from "../../assets/glossary.svg";
import { ReactComponent as AddGlossaryIcon } from "../../assets/addGlossaryIcon.svg";
import { ReactComponent as ChevronRight } from "../../assets/chevronRight.svg";
import { ReactComponent as LeftChevron } from "../../assets/leftChevron.svg";
import { ReactComponent as GlossaryDeleteIcon } from "../../assets/glossaryDeleteIcon.svg";
import { ReactComponent as GlossaryEditIcon } from "../../assets/glossaryEditIcon.svg";
import { ReactComponent as GlossaryChevronUp } from "../../assets/glossaryChevronUp.svg";
import { ReactComponent as GlossaryViewIcon } from "../../assets/glossaryViewIcon.svg";
import ExpandLessRoundedIcon from "@mui/icons-material/ExpandLessRounded";
import ExpandMoreRoundedIcon from "@mui/icons-material/ExpandMoreRounded";

import { ReactComponent as GlossaryInfoIcon } from "../../assets/glossaryInfoIcon.svg";
import { ReactComponent as AlertInfo } from "../../assets/alertInfo.svg";
import { ReactComponent as PlayIcon } from "../../assets/playIcon.svg";


import CircularProgress from "@mui/material/CircularProgress";
import { ToastContainer, toast } from "react-toastify";

import DoneIcon from "@mui/icons-material/Done";
import { Button, TextField, Tooltip, styled } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  addGlossarytoTheBook,
  deleteGlossaryFromTheBook,
  getGlossaryAffectedParagraphs,
  updateGlossarytoTheBook,
} from "../../utils/glossaryUtils";
import {
  getUserNameFromEmail,
  scrollToHighlightedText,
  searchTextOverBook,
} from "../../utils/translationUtil";
import GlossaryScopePopup from "../glossaryScopePopup/GlossaryScopePopup";
import GlossaryWarningModal from "../glosssaryWarningModal/GlossaryWarningModal";
import AccordianGlossary from "../Accordian/AccordianGlossary";
import AddGlossaryForm from "../AddGlossaryForm/AddGlossaryForm";
import { useParams } from "react-router-dom";
import store from "../../App/store";
import GlossaryApplicationStatus from "../GlossaryApplicationStatus/GlossaryApplicationStatus";
import { setGlossaryAppliedData } from "../../App/editor/editor.actions";
import { collection, onSnapshot } from "firebase/firestore";
import { firestore } from "../../firebase";

const AddToGlossary = ({
  onClose,
  highlightedEnglish,
  chapter,
  // glossaryData,
  selectedEnglishWord,
  glossarySearchInput,
  setGlossarySearchInput,
  handleCloseGlossary,
  getBookGlossaryData,
  handleApplyGlossary,
  handlePopoverVisibility,
  isPopoverOpen,
  setSelectedGlossary,
  selectedGlossary,
  setSelectedEnglishWord,
  sourceEditor,
  handleChapterChange,
  fetchTranslatedChapterPending,
}) => {
  // console.log("englishWord",englishWord)
  const params = useParams();

  const { loggedInUserEmail } = useSelector((state) => state.main);
  const [isLoading, setIsLoading] = useState(false);
  const [showVariantInputFeild, setShowVariantInputFeild] = useState(false);
  const [deleteWarningData, setDeleteWarningData] = useState(null);
  const [highlightedEnglishText, setHighlightedEnglishText] = useState("");
  const [glossaryMatchesData, setGlossaryMatchesData] = useState([]);
  const [findOverBookData, setFindOverBookData] = useState(null);
  const [isFirstOccurence, setIsFirstOccurence] = useState(false);
  const [findGlossaryData, setFindGlossaryData] = useState({
    totalOccurances: 0,
    currentIteration: 0,
  });
  const [showGlossaryNotFoundWarning, setShowGlossaryNotFoundWarning] =
    useState(false);
  const [openedGlossaryData, setOpenedGlossaryData] = useState("");
  const dispatch=useDispatch()
  const { glossaryList: glossaryData } = useSelector((state) => state.editor);
  const [sectionType, setSectionType] = useState("glossaryMatches");


  const updateFindTextResults = async (englishWord) => {
    const findAndReplaceEditing = sourceEditor.plugins.get(
      "FindAndReplaceEditing"
    );
    console.log("editorFindings", findAndReplaceEditing);

    let totalCountsOverBook = 0;
    let OverBookIterationNo = 0;
    let wordOccurrencesOverBook;
    if (!findOverBookData || findOverBookData?.englishWord !== englishWord) {
      const data =
        (await searchTextOverBook(
          params.id,
          englishWord,
          "false",
          "true",
          "english"
        )) ?? {};

      wordOccurrencesOverBook = data.word_occurrences;
      setFindOverBookData({
        englishWord: englishWord,
        wordOccurrencesOverBook,
        chapterWiseOccurancePara:data?.chapter_paragraph_occurrences
      });
    } else {
      wordOccurrencesOverBook = findOverBookData?.wordOccurrencesOverBook;
    }

    for (const key in wordOccurrencesOverBook) {
      if (Number(key) !== chapter?.chapter_number)
        totalCountsOverBook += wordOccurrencesOverBook[key];

      if (Number(key) < chapter?.chapter_number) {
        OverBookIterationNo += wordOccurrencesOverBook[key];
      }
    }

    let currentChapterOccurances =
      findAndReplaceEditing?.state?.results?.length ?? 0;
    let currentChapterIteration =
      findAndReplaceEditing?.state?.highlightedOffset ?? 0;

    setFindGlossaryData({
      englishWord: englishWord,
      totalOccurances: totalCountsOverBook + currentChapterOccurances,
      currentIteration: currentChapterIteration + OverBookIterationNo,
    });
    scrollToHighlightedText("source");
  };

  const isChaptersLastOccurances = (type) => {
    if (!findOverBookData?.wordOccurrencesOverBook) return;
    const findAndReplaceEditing = sourceEditor.plugins.get(
      "FindAndReplaceEditing"
    );
    let currentChapterOccurances =
      findAndReplaceEditing?.state?.results?.length ?? 0;
    let currentChapterIteration =
      findAndReplaceEditing?.state?.highlightedOffset ?? 0;

    const chapterArray = Object.keys(
      findOverBookData?.wordOccurrencesOverBook
    )?.sort((a, b) => Number(a) - Number(b));
    let currentChapterNo = store.getState().editor.currentChapter;
    let currentChapterIndex = chapterArray.findIndex(
      (chapterNo) => Number(chapterNo) === currentChapterNo
    );

    if (
      type === "next" &&
      currentChapterOccurances === currentChapterIteration &&
      currentChapterIndex + 1 < chapterArray.length
    ) {
      handleChapterChange(Number(chapterArray[currentChapterIndex + 1]));
      return true;
    }

    if (
      type === "prev" &&
      currentChapterIteration === 1 &&
      currentChapterIndex - 1 >= 0
    ) {
      handleChapterChange(Number(chapterArray[currentChapterIndex - 1]));
      setTimeout(() => {
        setIsFirstOccurence(true);
      });
      return true;
    }

    return false;
  };

  const findWordInSourceEditor = (operation, englishText, isFirstOccurence) => {
    if (fetchTranslatedChapterPending) return;

    const findAndReplaceEditing = sourceEditor.plugins.get(
      "FindAndReplaceEditing"
    );
    findAndReplaceEditing?.state?.set("matchWholeWords", true);

    if (isFirstOccurence) {
      setTimeout(() => {
        findAndReplaceEditing?.state?.set(
          "highlightedResult",
          findAndReplaceEditing?.state?.results?.get(
            findAndReplaceEditing?.state?.results?.length - 1 ?? 0
          )
        );
        updateFindTextResults(englishText);
      }, 10);
      setIsFirstOccurence(false);
      return;
    }
    console.log("sourceEditor",findAndReplaceEditing)
    sourceEditor.execute(operation, englishText);
  };

  useEffect(() => {
    if (chapter && highlightedEnglishText) {
      const finalEnglishWord =
        findGlossaryData.englishWord ?? highlightedEnglishText;
      findWordInSourceEditor("find", finalEnglishWord, isFirstOccurence);
      updateFindTextResults(finalEnglishWord);
    }
  }, [
    chapter,
    isFirstOccurence,
    highlightedEnglishText,
    fetchTranslatedChapterPending,
  ]);

  useEffect(() => {
    if (highlightedEnglish || highlightedEnglishText) {
      let finalEnglishWord = highlightedEnglish || highlightedEnglishText;
      setHighlightedEnglishText(finalEnglishWord);
      console.log(
        "drDetach",
        glossaryData,
        highlightedEnglishText,
        highlightedEnglish
      );
      const lowercasedSearchTerm = finalEnglishWord.trim().toLowerCase();
      const newFilteredData = glossaryData.filter((glossary) => {
        if (
          glossary.english_word.toLocaleLowerCase().trim() ===
          lowercasedSearchTerm
        )
          setOpenedGlossaryData(glossary);

        if (
          glossary.english_word.toLowerCase().includes(lowercasedSearchTerm) ||
          lowercasedSearchTerm.includes(glossary.english_word.toLowerCase())
        )
          return true;

        let isVariantFound = false;
        glossary.dutch_word_array.forEach((variant) => {
          if (
            variant.replacement_dutch_word
              .toLowerCase()
              .includes(lowercasedSearchTerm)
          )
            isVariantFound = true;
        });

        return isVariantFound;
      });
      setGlossaryMatchesData(newFilteredData);
      document.documentElement.style.setProperty(
        `--glossary-highlight-bg`,
        "#3B37FF33"
      );
      document.documentElement.style.setProperty(
        `--glossary-highlight-transparent-bg`,
        "transparent"
      );

      if (!newFilteredData.length) {
        findWordInSourceEditor("find", finalEnglishWord);
        updateFindTextResults(finalEnglishWord);
        if (!showVariantInputFeild && highlightedEnglish)
          setShowGlossaryNotFoundWarning(true);
      }
    }
  }, [highlightedEnglish, glossaryData]);



  const CustomTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(() => ({
    [`& .MuiTooltip-tooltip`]: {
      backgroundColor: "black",
      color: "white",
    },
    "& .MuiTooltip-arrow": {
      color: "black", // Arrow color
    },
  }));

  useEffect(() => {
    // const glossaryElem = document.querySelector(".glossaryContainer");
    // glossaryElem.style.zIndex = "10";
    
    const updateGlossaryAppliedData= async ()=>{
      if(!chapter) return 
      // const glossaryAppliedData=await getGlossaryAffectedParagraphs(chapter.translation_id)
      // dispatch(setGlossaryAppliedData(glossaryAppliedData))
  
      // const collectionRef = collection(firestore, "live_glossary_application");
      // const unsubscribe = onSnapshot(collectionRef, (snapshot) => {
      //   const newData = snapshot.docs.map((doc) => ({
      //     id: doc.id,
      //     ...doc.data(),
      //   })).filter((doc)=>doc.id===params.id);

      //   console.log("newData",newData)
      //   const currentBookData=newData[0];
      //   let formatedData={}

      //   formatedData[currentBookData.english_word]={
      //     glossaryItemDetails:{english_word:currentBookData.english_word,glossary_item_id:currentBookData.glossary_item_id} ,
      //     affectedChaptersData: currentBookData.updated_resources,
      //     status:'Applying'
      //   }
      //   dispatch(setGlossaryAppliedData(formatedData)) 
      // }) 
      // return ()=>unsubscribe()
    } 
    updateGlossaryAppliedData()
      
  }, []);

  // const addDutchVariants = () => {
  //   if (!inputVariant) {
  //     toast(`Input feild cannot be empty`, {
  //       type: "warning",
  //       autoClose: 3000,
  //     });
  //     return;
  //   }

  //   let isThisVariantAlreadyExists = dutchVarients.find(
  //     (varient) =>
  //       varient?.replacement_dutch_word?.toLowerCase() ===
  //       inputVariant?.trim()?.toLocaleLowerCase()
  //   );

  //   if (isThisVariantAlreadyExists) {
  //     toast(`Variant already exists`, {
  //       type: "warning",
  //       autoClose: 3000,
  //     });
  //     return;
  //   }

  //   if (!isThisVariantAlreadyExists || isThisVariantAlreadyExists === -1) {
  //     const newVariant = {
  //       metadata_storage: {
  //         "creation date": new Date(),
  //         creation_user: loggedInUserEmail,
  //         application_history: [],
  //       },
  //       replacement_dutch_word: inputVariant.trim(),
  //     };
  //     let updatedVariants=[...dutchVarients,newVariant]
  //     setDutchVarients(updatedVariants);
  //     handleUpdateGlossary(updatedVariants)
  //     setShowVariantInputFeild(false);
  //     setInputVariant("");
  //   }
  // };

  const getNewVariant = (inputData, bookInfo) => {
    return {
      ...bookInfo,
      metadata_storage: {
        "creation date": new Date(),
        creation_user: loggedInUserEmail,
        application_history: [],
      },
      instructions: {
        whenToApply: inputData.whenToApply,
        exception: inputData.exceptions,
      },
      variant_scope: inputData.scope,
      replacement_dutch_word: inputData?.replacement_dutch_word?.trim(),
    };
  };

  const isVariantAlreadyExists = (variant, variantList) => {
    let isThisVariantAlreadyExists = variantList?.find(
      (dutchVariant) =>
        dutchVariant?.replacement_dutch_word?.toLowerCase() ===
        variant?.trim()?.toLocaleLowerCase()
    );

    if (isThisVariantAlreadyExists) {
      toast(`Variant already exists`, {
        type: "warning",
        autoClose: 3000,
      });
      return true;
    }

    return false;
  };

  // const handleDeleteVarient = (variant) => {
  //   let updatedVariants=dutchVarients.filter(
  //     (dutchVariant) => dutchVariant.replacement_dutch_word !== variant
  //   )
  //   setDutchVarients(updatedVariants)
  //   handleUpdateGlossary(updatedVariants)
  // };

  const handleUpdateGlossary = async ({
    action,
    inputData,
    englishText,
    variant,
    type,
  }) => {
    if (isLoading) return;
    let toSearchEnglishWord = englishText;
    const isCurrentGlossaryExists = glossaryData.find(
      (glossary) =>
        glossary?.english_word?.toLowerCase() ===
        toSearchEnglishWord?.toLowerCase()
    );

    let updatedDutchVariants = isCurrentGlossaryExists?.dutch_word_array?.length
      ? [...isCurrentGlossaryExists?.dutch_word_array]
      : [];
    const bookInfo = {
      author: chapter.book_info.author_name,
      language: chapter?.translation_info?.translated_to,
      genre: chapter.book_info.genre,
    };

    switch (action) {
      case "add":
        if (!inputData.replacement_dutch_word.trim()) {
          toast(`Input feild cannot be empty`, {
            type: "warning",
            autoClose: 3000,
          });
          return;
        }

        if (!isCurrentGlossaryExists || isCurrentGlossaryExists === -1) {
          setIsLoading(true);
          const glossaryPayload = {
            unique_string: chapter.translation_id,
            glossary_item: {
              ...bookInfo,
              english_word: englishText,
              dutch_word_array: [getNewVariant(inputData, bookInfo)],
              glossary_item_scope: "Book",
              application_scope: "book_level",
              chapter_numbers: [],
            },
          };
          await addGlossarytoTheBook(glossaryPayload);
          // setDutchVarients([getNewVariant(inputVariant)]);
          // setInputVariant("");
          setShowVariantInputFeild(false);
          getBookGlossaryData();
          setIsLoading(false);
          return;
        }

        if (
          isCurrentGlossaryExists &&
          !isVariantAlreadyExists(
            inputData.replacement_dutch_word,
            isCurrentGlossaryExists.dutch_word_array
          )
        ) {
          updatedDutchVariants.unshift(getNewVariant(inputData, bookInfo));
        } else {
          return;
        }
        break;
      case "update":
        if (!inputData.replacement_dutch_word.trim()) {
          toast(`Input feild cannot be empty`, {
            type: "warning",
            autoClose: 3000,
          });
          return;
        }
        console.log("liveValue", inputData);

        updatedDutchVariants = updatedDutchVariants.map((dutchVariant) => {
          if (dutchVariant.replacement_dutch_word === variant) {
            dutchVariant.replacement_dutch_word =
              inputData.replacement_dutch_word.trim();
            dutchVariant.variant_scope = inputData.scope;
            dutchVariant.instructions.whenToApply =
              inputData.whenToApply.trim();
            dutchVariant.instructions.exception = inputData?.exceptions;
          }
          return dutchVariant;
        });
        break;
      case "delete":
        if (!updatedDutchVariants.length) return;

        updatedDutchVariants = updatedDutchVariants.filter(
          (dutchVariant) => dutchVariant?.replacement_dutch_word !== variant
        );

        if (!updatedDutchVariants.length || type === "glossaryItem") {
          await deleteGlossaryFromTheBook(isCurrentGlossaryExists.id);
          if (
            selectedGlossary.englishWord ===
            isCurrentGlossaryExists.english_word
          )
            setSelectedGlossary({ englishWord: "", variant: "" });

          return;
        }
        break;
      default:
        break;
    }

    const glossaryPayload = {
      ...bookInfo,
      english_word: isCurrentGlossaryExists?.english_word,
      dutch_word_array: updatedDutchVariants,
      glossary_item_scope: "Book",
      application_scope: "book_level",
      chapter_numbers: [],
      is_active: true,
      is_part_of_collection: false,
      application_history: null,
    };

    await updateGlossarytoTheBook(glossaryPayload, isCurrentGlossaryExists.id);

    // setDutchVarients(updatedDutchVariants);
    // setInputVariant("");
    setShowVariantInputFeild(false);
    // setSelectedVariantForEdit("");
    return updatedDutchVariants;
  };

  // const handleUpdateVariant = (variant) => {
  //   let isVariantAlreadyExist = false;
  //   const updatedDutchVariants = dutchVarients.map((dutchVariant) => {
  //     if (
  //       inputVariant?.trim()?.toLowerCase() ===
  //       dutchVariant?.replacement_dutch_word?.toLowerCase()
  //     ) {
  //       toast(`Variant already exists`, {
  //         type: "warning",
  //         autoClose: 3000,
  //       });
  //       isVariantAlreadyExist = true;
  //     }

  //     if (dutchVariant.replacement_dutch_word === variant) {
  //       dutchVariant.replacement_dutch_word = inputVariant.trim();
  //     }
  //     return dutchVariant;
  //   });

  //   if (isVariantAlreadyExist) return;
  //   setDutchVarients(updatedDutchVariants);
  //   handleUpdateGlossary(updatedDutchVariants)
  //   setInputVariant("");
  //   setShowVariantInputFeild(false);
  //   setSelectedVariantForEdit("");
  // };

  const handleDeleteGlossaryThroughPopup = () => {
    if (deleteWarningData.type === "Glossary") {
      handleUpdateGlossary("delete", "dummyVariant", "glossaryItem");
    } else {
      handleUpdateGlossary("delete", deleteWarningData.variant, "variant");
    }

    setDeleteWarningData(null);
  };

  const handleCloseGlossaryPannel = () => {
    if (isLoading) return;
    const findAndReplaceEditing = sourceEditor.plugins.get(
      "FindAndReplaceEditing"
    );

    findAndReplaceEditing?.state?.results?.clear();
  
    handleCloseGlossary();
    document.documentElement.style.removeProperty(
      `--glossary-highlight-bg`,
      "#3B37FF33"
    );
    setFindOverBookData(null);
    document.documentElement.style.removeProperty(
      `--glossary-highlight-transparent-bg`,
      "transparent"
    );
    setFindOverBookData({ englishWord: "", wordOccurrencesOverBook: "" ,chapterWiseOccurancePara:""});
    setFindGlossaryData({
      englishWord: "",
      totalOccurances: "",
      currentIteration: "",
    });
  };


  

  return (
    <>
      <div
        style={{ height: "calc(100dvh - 133px - 71px)" }}
        className={`w-[576px] shrink-0 top-[100px] left-4 glossaryContainer fixed  gap-2 flex 
            "pb-[67.46px]" 
           flex-col px-3 bg-[#FFFFFF] my-12  rounded-md`}
      >
        <ToastContainer hideProgressBar={true} />
        {sectionType === "glossaryMatches" && (
          <>
            <div className="flex justify-between">
              <div className="flex items-center gap-2">
                <span className="text-sm text-[#1E1C80]">
                  Glossary entries for "{highlightedEnglishText}"
                </span>
              </div>
              <div>
                <CloseIcon
                  className="cursor-pointer"
                  onClick={handleCloseGlossaryPannel}
                />
              </div>
            </div>
            <div className="text-[#5F6368] text-sm font-[Poppins]">
              Match found ({glossaryMatchesData.length})
            </div>
            {!glossaryMatchesData.length && showGlossaryNotFoundWarning && (
              <div
                className="flex gap-[22px] text-sm items-start rounded-[4px] py-3 px-4 bg-[#FFFBE6]"
                style={{ border: "1px solid #FFE58F" }}
              >
                <div className="flex gap-2 items-start">
                  <AlertInfo />
                  <span className="-mt-1">
                    No translations found for "{highlightedEnglishText}". You
                    can add a glossary entry below
                  </span>
                </div>
                <CloseIcon
                  onClick={() => setShowGlossaryNotFoundWarning(false)}
                  className="cursor-pointer text-base text-[#8C8A7E]"
                />
              </div>
            )}
            <div
              style={{ height: "calc(100dvh - 133px - 180px - 20px)" }}
              className="flex flex-col overflow-y-scroll hideSourceScrollBar gap-2"
            >
              {glossaryMatchesData.length > 0 && (
                <div className="flex flex-col gap-2">
                  {glossaryMatchesData.map((glossary) => {
                    return (
                      <AccordianGlossary
                        openedGlossaryData={openedGlossaryData}
                        glossary={glossary}
                        chapter={chapter}
                        setGlossaryMatchesData={setGlossaryMatchesData}
                        glossaryMatchesData={glossaryMatchesData}
                        setOpenedGlossaryData={setOpenedGlossaryData}
                        sourceEditor={sourceEditor}
                        handleUpdateGlossary={handleUpdateGlossary}
                        setSelectedGlossary={setSelectedGlossary}
                        selectedGlossary={selectedGlossary}
                        glossaryData={glossaryData}
                        updateFindTextResults={updateFindTextResults}
                        highlightedEnglishText={highlightedEnglishText}
                      />
                    );
                  })}
                </div>
              )}
              {!showVariantInputFeild && !glossaryMatchesData.length && (
                <div
                  onClick={() => {
                    setShowVariantInputFeild(true);
                    setShowGlossaryNotFoundWarning(false);
                  }}
                  className="flex flex-col gap-3"
                >
                  <div className="py-1 bg-[#F5F7FF]  px-2 rounded-[4px]">
                    {highlightedEnglishText}
                  </div>
                  <div className="py-2 text-[12px] text-[#3B37FF] rounded-[4px]  h-[211px] bg-[#F8F9FC] px-2 cursor-pointer flex items-center justify-center gap-1">
                    <AddGlossaryIcon className="text-[#3B37FF]" />
                    Add translation
                  </div>
                </div>
              )}
              {showVariantInputFeild && (
                <div className="bg-[#F8F9FC] flex flex-col px-3 gap-2 rounded-[4px]">
                  <div className="py-1 rounded-[4px]">
                    {highlightedEnglishText}
                  </div>
                  <AddGlossaryForm
                    chapter={chapter}
                    setShowVariantInputFeild={setShowVariantInputFeild}
                    setGlossaryMatchesData={setGlossaryMatchesData}
                    setOpenedGlossaryData={setOpenedGlossaryData}
                    englishWord={highlightedEnglishText}
                    glossaryMatchesData={glossaryMatchesData}
                    handleUpdateGlossary={handleUpdateGlossary}
                    operationType={"add"}
                    setSelectedGlossary={setSelectedGlossary}
                  />
                  <div className="py-2 text-[12px] text-[#3B37FF] cursor-pointer flex items-center justify-start gap-1">
                    <AddGlossaryIcon className="text-[#3B37FF]" />
                    Add translation
                  </div>
                </div>
              )}
            </div>
          </>
        )}
        {sectionType === "applicationStatus" && (
          <GlossaryApplicationStatus
            handleCloseGlossaryPannel={handleCloseGlossaryPannel}
            sectionType={sectionType}
            setSectionType={setSectionType}
            highlightedEnglishText={highlightedEnglishText}
            findOverBookData={findOverBookData}
            sourceEditor={sourceEditor}
            findGlossaryData={findGlossaryData}
            updateFindTextResults={updateFindTextResults}
            handleChapterChange={handleChapterChange}
            fetchTranslatedChapterPending={fetchTranslatedChapterPending}
          />
        )}
        <div
          style={{ boxShadow: "0px -6px 20px 0px rgba(0, 0, 0, 0.05)" }}
          className="footer py-2 flex justify-between items-center right-0 px-3   absolute bottom-0 w-full "
        >
          <div
            onClick={() => {
              setSectionType("applicationStatus");
            }}
            className="text-[14px] flex gap-[6px] items-center cursor-pointer text-[#000000D9] font-[poppins] font-[500]"
          > 
          { sectionType==="glossaryMatches"&& <> <PlayIcon/>
           <span>
            Total occurrences - {findGlossaryData?.currentIteration}/
            {findGlossaryData?.totalOccurances}
            </span></>
            } 
          </div>
          <div className="flex gap-[15px] items-center">
            <Button
              disabled={findGlossaryData?.currentIteration <= 1}
              className="cursor-pointer py-[6px] px-0 gap-1 flex items-center"
              onClick={() => {
                if (!isChaptersLastOccurances("prev")) {
                  sourceEditor.execute("findPrevious");
                  updateFindTextResults(findGlossaryData.englishWord);
                }
              }}
            >
              <ExpandLessRoundedIcon />
              Previous
            </Button>
            <Button
              disabled={
                findGlossaryData?.currentIteration ===
                findGlossaryData?.totalOccurances
              }
              className="cursor-pointer py-[6px] px-0 gap-1 flex"
              onClick={() => {
                if (!isChaptersLastOccurances("next")) {
                  sourceEditor.execute("findNext");
                  updateFindTextResults(findGlossaryData.englishWord);
                }
              }}
            >
              Next
              <ExpandMoreRoundedIcon />
            </Button>
            <Button
              disabled={true}
              variant="outlined"
              className="flex rounded-[4px] relative text-[12px] py-[6px] px-3 gap-2"
            >
              <div className="flex items-center">Apply</div>
            </Button>
            <div className="relative">
              <Button
                disabled={selectedGlossary.variant ? false : true}
                onClick={()=>{handleApplyGlossary()
                  setSectionType("applicationStatus")
                }}
                variant="contained"
                className="flex rounded-[4px] relative text-[12px] py-[6px] px-3 gap-2"
              >
                <div className="flex items-center">Apply All</div>
              </Button>
              {/* {isPopoverOpen && (
              <GlossaryScopePopup
                handleApplyGlossary={handleApplyGlossary}
                handlePopoverVisibility={handlePopoverVisibility}
              />
            )} */}
            </div>
          </div>
        </div>
        {deleteWarningData && (
          <GlossaryWarningModal
            deleteWarningData={deleteWarningData}
            closePopup={() => setDeleteWarningData(null)}
            handleDelete={handleDeleteGlossaryThroughPopup}
          />
        )}
      </div>
      {/* </div> */}
    </>
  );
};

export default AddToGlossary;
