import React, { useCallback, useEffect, useState } from "react";

import "./AddToGlossaryForm.scss";
import { ReactComponent as Stars } from "../../assets/stars.svg";
import { ToastContainer, toast } from "react-toastify";
import { useSelector } from "react-redux";
import apiUrls from "../../api/apiUrl";
import debounce from "lodash.debounce";
import axios from "axios";

function AddGlossaryForm({
  chapter,
  setShowVariantInputFeild,
  setOpenedGlossaryData,
  setGlossaryMatchesData,
  englishWord,
  glossaryMatchesData,
  handleUpdateGlossary,
  operationType,
  setSelectedGlossary,
  variantDataForEdit,
  setVariantDataForEdit,
}) {
  const [variantInputData, setVariantInputData] = useState({
    replacement_dutch_word: "",
    scope: "Language",
    whenToApply: "",
    exceptions: "",
  });
  const [showFeilds, setShowFeilds] = useState({
    exception: false,
    whenToApplySuggestions: true,
  });
  const [varinatContextSuggestions, setvarinatContextSuggestions] = useState(
    []
  );

  useEffect(() => {
    if (variantDataForEdit) {
      if(variantDataForEdit.exceptions)
        setShowFeilds({...showFeilds,exception:true})
      setVariantInputData(variantDataForEdit);
    }
  }, [variantDataForEdit]);

  const { userOpenedBookInfo } = useSelector((state) => state.main);

  const getVariantContextSuggestions = async (englishWord, dutchWord) => {
    try {
      const response = await axios.post(
        `${
          process.env.REACT_APP_BACKEND_URL + apiUrls.variantContextSuggestions
        }`,
        {
          english_word: englishWord,
          dutch_variant: dutchWord,
        }
      );

      console.log("drResponse", response.data);
    } catch (error) {
      console.log("error in getting glossary");
    }
  };

  const debounceGetVariantContextSuggestions = useCallback(
    debounce(
      (englishWord, dutchWord) =>
        getVariantContextSuggestions(englishWord, dutchWord),
      1000
    ),
    []
  );

  console.log("liveValue",variantInputData)

  const handleVariantInputChange = (e) => {
    setVariantInputData({
      ...variantInputData,
      [e.target.name]: e?.target?.value,
    });

    // if(e.target.name==="replacement_dutch_word"){
    //   debounceGetVariantContextSuggestions(englishWord,e?.target?.value)
    // }
  };

  const isVariantAlreadyExists = (variant, variantList) => {
    if(operationType==="update") return ;

    let isThisVariantAlreadyExists = variantList?.find(
      (dutchVariant) =>
        dutchVariant?.replacement_dutch_word?.toLowerCase() ===
        variant?.trim()?.toLocaleLowerCase()
    );

    if (isThisVariantAlreadyExists) {
      toast(`Variant already exists`, {
        type: "warning",
        autoClose: 3000,
      });
      return true;
    }

    return false;
  };

  const handleSaveGlossary = async () => {
    if (
      !variantInputData.replacement_dutch_word ||
      !variantInputData.scope ||
      !variantInputData.whenToApply
    )
      return;

    const isGlossaryExist = glossaryMatchesData?.find(
      (glossary) => glossary?.english_word === englishWord
    );

    if (
      isGlossaryExist &&
      isVariantAlreadyExists(
        variantInputData.replacement_dutch_word.trim(),
        isGlossaryExist.dutch_word_array
      )
    ) {
      return;
    }
     
    let updateGlossaryData={
      action: operationType,
      inputData: variantInputData,
      englishText: englishWord,
    }

    if(operationType==="update") 
      updateGlossaryData.variant=variantDataForEdit.replacement_dutch_word
    
    const updatedVariants = await handleUpdateGlossary(updateGlossaryData);

    setShowVariantInputFeild(false);

    if (setOpenedGlossaryData) {
      setOpenedGlossaryData({
        english_word: englishWord,
        dutch_word_array: [variantInputData],
      });
    }

    if (isGlossaryExist && updatedVariants?.length) {
      setGlossaryMatchesData((prev) =>
        prev.map((glossary) => {
          if (glossary.english_word === englishWord) {
            glossary.dutch_word_array = updatedVariants;
          }
          return glossary;
        })
      );
    }

    setSelectedGlossary({
      englishWord: englishWord,
      variant: variantInputData?.replacement_dutch_word,
    });
   
    if(setVariantDataForEdit)
      setVariantDataForEdit({
        replacement_dutch_word: "",
        scope: "Language",
        whenToApply: "",
        exceptions: "",
      });
    
    

    setVariantInputData({
      replacement_dutch_word: "",
      scope: "Language",
      whenToApply: "",
      exceptions: "",
    });
  };

  return (
    <div
      style={{ border: "1px solid #3961FF" }}
      className="p-5  gap-2 flex flex-col rounded-[4px] bg-white"
    >
      <div className="flex flex-col gap-2">
        <span className="text-[12px] text-[#000000D9] font-[poppins] font-[500]">
          Dutch Translation :
        </span>
        <input
          style={{ border: "1px solid #D9D9D9" }}
          placeholder="Type here"
          className="w-full text-sm rounded-[4px]  px-3 py-[5px] outline-none"
          name="replacement_dutch_word"
          value={variantInputData.replacement_dutch_word}
          onChange={handleVariantInputChange}
          autoComplete="off"
        />
      </div>
      <div className="w-full flex flex-col gap-2 relative">
        <label
          htmlFor="book-genre"
          className="text-[12px] text-[#000000D9] font-[poppins] font-[500]"
        >
          Apply this for:
        </label>

        <div className=" w-full">
          <select
            id="book-sub-genre"
            className={`w-full  text-[#242424] py-[5px] px-3 outline-none text-sm`}
            placeholder="Enter the Sub-Genre of the book"
            style={{
              borderRadius: "4px",
              borderColor: "#D9D9D9",
              fontSize: "14px",
            }}
            onChange={handleVariantInputChange}
            name="scope"
            value={variantInputData.scope}
          >
            {/* <option value="" disabled selected hidden>Select the Sub-Genre of the book</option> */}
            <option className="text-sm " value={"Book"} key={"book"}>
              Current book:{" "}
              {userOpenedBookInfo.title ?? chapter?.book_info?.book_title}
            </option>
            <option className="text-sm" value={"Author"} key={"author"}>
              Author:{" "}
              {userOpenedBookInfo?.author ?? chapter?.book_info?.author_name}
            </option>
            <option className="text-sm" value={"Genre"} key={"genre"}>
              Genre: {userOpenedBookInfo?.genre ?? "Romance"}
            </option>
            <option className="text-sm" value={"Language"} key={"language"}>
              Language: {userOpenedBookInfo?.translatedTo ?? "Dutch"}
            </option>
          </select>
        </div>
      </div>
      <div className="flex flex-col gap-2">
        <div className="flex flex-col gap-2">
          <div className="flex items-center justify-between">
            <span className="text-[12px] text-[#000000D9] font-[poppins] font-[500]">
              When to apply:
            </span>
            <div
              onClick={() => setShowFeilds({ ...showFeilds, exception: true })}
              className="text-[12px] text-[#3B37FF] cursor-pointer"
            >
              Add exceptions
            </div>
          </div>
          <textarea
            style={{ border: "1px solid #D9D9D9" }}
            onChange={handleVariantInputChange}
            name="whenToApply"
            placeholder="Type here"
            value={variantInputData.whenToApply}
            className="w-full h-[54px] hideSourceScrollBar resize-none  text-sm rounded-[4px]  px-3 py-[5px] outline-none "
          />
        </div>
        {/* {showFeilds.whenToApplySuggestions && (
          <div className="flex flex-col gap-2">
            <div
              style={{ border: "1px solid #D9D9D9" }}
              onClick={() => {
                setVariantInputData({
                  ...variantInputData,
                  whenToApply:
                    "When someone inflates or amplifies a fact beyond truth.",
                });
                setShowFeilds({ ...showFeilds, whenToApplySuggestions: false });
              }}
              className="flex gap-2 py-1 px-2 cursor-pointer text-[10px] w-fit bg-[#F5F5F5] items-center"
            >
              <Stars />{" "}
              <span>
                When someone inflates or amplifies a fact beyond truth.
              </span>
            </div>
          </div>
        )} */}
        {showFeilds.exception && (
          <input
            style={{ border: "1px solid #D9D9D9" }}
            onChange={handleVariantInputChange}
            name="exceptions"
            placeholder="Type the exceptions here"
            value={variantInputData.exceptions}
            autoComplete="off"
            className="w-full text-sm rounded-[4px]  px-3 py-[5px] outline-none "
          />
        )}
      </div>
      <div className="flex items-center gap-[17px] text-xs">
        <span
          className="cursor-pointer text-[#979797]"
          onClick={() => {
            setVariantInputData({
              english_word: englishWord,
              replacement_dutch_word: "",
              scope: "Language",
              whenToApply: "",
              exceptions: "",
            });
            setShowFeilds({ ...showFeilds, whenToApplySuggestions: true });
          }}
        >
          Reset
        </span>
        <span
          onClick={handleSaveGlossary}
          className="text-[#3B37FF] cursor-pointer"
        >
          Save
        </span>
      </div>
    </div>
  );
}

export default AddGlossaryForm;
