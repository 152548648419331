import React, { useEffect, useState } from "react";
import { ReactComponent as LeftChevron } from "../../assets/leftChevron.svg";
import CloseIcon from "@mui/icons-material/Close";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import { ReactComponent as GlossaryViewIcon } from "../../assets/glossaryViewIcon.svg";
import { ReactComponent as PendingOutline } from "../../assets/pendingOutline.svg";
import { ReactComponent as CheckCircle } from "../../assets/checkCircle.svg";
import { ReactComponent as EditPencil } from "../../assets/editPencil.svg";

import "./GlossaryApplicationStatus.scss";
import store from "../../App/store";
import { useSelector } from "react-redux";
function GlossaryApplicationStatus({
  sectionType,
  setSectionType,
  highlightedEnglishText,
  handleCloseGlossaryPannel,
  findOverBookData,
  sourceEditor,
  findGlossaryData,
  updateFindTextResults,
  handleChapterChange,
  fetchTranslatedChapterPending,
}) {
  const [updateIterationData, setUpdateIterationData] = useState({
    chapterNo: "",
    iterationNo: "",
  });
  const [expanded, setExpanded] = useState(true);
  const { glossaryAppliedData ,isGlossaryApplying} = useSelector((state) => state.editor);
  const [occurrencesListData, setOccurrencesListData] = useState([]);
  const [glossaryApplicationStatus, setGlossaryApplicationStatus] = useState(
    {}
  );

  useEffect(() => {
    if (!fetchTranslatedChapterPending && updateIterationData.chapterNo) {
      const findAndReplaceEditing = sourceEditor.plugins.get(
        "FindAndReplaceEditing"
      );

      setTimeout(() => {
        findAndReplaceEditing?.state?.set(
          "highlightedResult",
          findAndReplaceEditing?.state?.results?.get(
            updateIterationData.iterationNo - 1 ?? 0
          )
        );
      }, 10);
      setUpdateIterationData({ chapterNo: "", iterationNo: "" });
    }
  }, [fetchTranslatedChapterPending]);

  const handleRedirection = (iteration, chapterNo) => {
    let currentChapterNo = store.getState().editor.currentChapter;
    const findAndReplaceEditing = sourceEditor.plugins.get(
      "FindAndReplaceEditing"
    );

    if (currentChapterNo === chapterNo) {
      if (findGlossaryData.currentIteration === iteration) return;
      findAndReplaceEditing?.state?.set(
        "highlightedResult",
        findAndReplaceEditing?.state?.results?.get(iteration - 1 ?? 0)
      );
      updateFindTextResults(highlightedEnglishText);
      return;
    }

    handleChapterChange(chapterNo);
    setUpdateIterationData({ chapterNo: chapterNo, iterationNo: iteration });
  };

  useEffect(() => {
    const getHighlightedTextSentence = () => {
      let chapterWiseOccuranceData=findOverBookData?.chapterWiseOccurancePara
      if (!chapterWiseOccuranceData) return;
      // const allTranslatedParagraphs = document.querySelectorAll(
        //   `#source .ck-editor__editable > p`
        // );
        const finalData={}
        Object.keys(chapterWiseOccuranceData).forEach((chapterNo)=>{
          let resultTexts = [];
          let textContainsParagraph = chapterWiseOccuranceData?.[chapterNo]?.English || [];
          console.log("chapterWiseOccuranceData",textContainsParagraph,chapterWiseOccuranceData)
      
        textContainsParagraph?.forEach((paragraph) => {
          // Get the full rendered text of the paragraph
          const parser = new DOMParser();
          const paragraphElement=parser.parseFromString(paragraph, "text/html");
          const fullText = paragraphElement?.body?.textContent || "";
          const regex = new RegExp(
            `\\b${findOverBookData?.englishWord || highlightedEnglishText}\\b`,
            "gi"
          );
          const matches = [...fullText.matchAll(regex)];
          console.log("resultTexts", highlightedEnglishText, matches);
          matches.forEach((match) => {
            // Get the position of "the"
            const position = match.index;
  
            // Get the part of the paragraph before "the"
            const beforeThe = fullText.slice(0, position).trim();
            const afterThe = fullText.slice(position + match[0].length).trim();
            const wordsAfter = afterThe.split(/\s+/);
  
            // Split it into words
            const wordsBefore = beforeThe.split(/\s+/);
  
            // Get the last three words before "the"
            const lastThreeWords = wordsBefore.slice(-3).join(" ");
            const nextThreeWords = wordsAfter.slice(0, 3).join(" ");
  
            resultTexts.push(
              lastThreeWords +
                ` <span class="highlightText">${match[0]}</span> ` +
                nextThreeWords
            );
          });
        });

        finalData[chapterNo]= resultTexts 
      })
     

      // allTranslatedParagraphs.forEach((paragraph) => {
      //   // Find if the paragraph contains any span with 'ck-find-result'
      //   if (paragraph.querySelector(".ck-find-result")) {
      //     textContainsParagraph.push(paragraph);
      //   }
      // });

    
      console.log("resultTexts", finalData); // Output the final array of surrounding texts
      setOccurrencesListData(finalData);
    };

   

    getHighlightedTextSentence();
  }, [findOverBookData]);

  useEffect(() => {
    let applicationStatus = {};
    let currentGlossaryData =
    glossaryAppliedData?.[
      findOverBookData?.englishWord || highlightedEnglishText
    ];
    Object?.keys(findOverBookData?.wordOccurrencesOverBook)?.forEach(
      (chapterNo) => {
        let occurencesData = {};
        for (
          let i = 1;
          i <= findOverBookData?.wordOccurrencesOverBook[chapterNo];
          i++
        ) {
          occurencesData[i] = isGlossaryApplying.status ? "Pending" : currentGlossaryData?.status==="Applied"?"Applied":"NotApplied";
        }
        applicationStatus[chapterNo] = occurencesData;
      }
    );
   
    if (currentGlossaryData) {
      if (currentGlossaryData?.status === "Applying" && isGlossaryApplying.status) {
        Object.keys(currentGlossaryData?.affectedChaptersData || {})?.forEach(
          (chapterNo) => {
            let affectedChaptersData =
              currentGlossaryData?.affectedChaptersData?.[chapterNo]
                ?.changes_data;
                affectedChaptersData.forEach((changedParagraphData)=>{
                  changedParagraphData?.paragraph_occurrences_array?.forEach(
                    (occuranceNo) => {
                      applicationStatus[chapterNo][occuranceNo] = "Applied";
                      console.log("occuranceNo",occuranceNo,chapterNo)
                    }
                  );
                })
          }
        );
      }
      
    }
    console.log(
      "currentGlossaryData",
      currentGlossaryData,
      applicationStatus,
      isGlossaryApplying
    );
    setGlossaryApplicationStatus(applicationStatus);
  }, [glossaryAppliedData,isGlossaryApplying]);

  const handleChange = () => {
    setExpanded(!expanded);
  };

  const getChapterApplicationStatus = (chapterNo) => {
    if (!glossaryApplicationStatus[chapterNo] || glossaryApplicationStatus[chapterNo][1] === "NotApplied") return;
    let status="Applied"
    Object?.keys(glossaryApplicationStatus?.[chapterNo])?.forEach((occuranceNo) => {
      if (glossaryApplicationStatus?.[chapterNo]?.[occuranceNo] === "Pending")
        status= "Pending";
    });

    return status;
  };

  const getOccuranceNo=(chapterNo,occuranceNo)=>{
    let finalOccuranceNo=0;
    const keys = Object.keys(findOverBookData?.wordOccurrencesOverBook || {})?.sort((a, b) => a - b) || [];
    console.log("chatak",keys)
    for (let i = 0; i < keys.length; i++) {
      const chapter = keys[i];
      if(chapter < chapterNo){
        finalOccuranceNo+=findOverBookData.wordOccurrencesOverBook[chapter]
      };
    }

   return finalOccuranceNo+occuranceNo
  }

  return (
    <div className="flex flex-col gap-2">
      <div className="flex justify-between">
        <div className="flex items-center gap-2">
          <LeftChevron
            className="cursor-pointer"
            onClick={() => {
              setSectionType("glossaryMatches");
            }}
          />{" "}
          <span className="text-base text-[#1E1C80]">
            Total occurences for "
            {findOverBookData?.englishWord || highlightedEnglishText}"
          </span>
        </div>
        <div>
          <CloseIcon
            className="cursor-pointer"
            onClick={() => {
              handleCloseGlossaryPannel();
              setUpdateIterationData({ chapterNo: "", iterationNo: "" });
            }}
          />
        </div>
      </div>
      <div
        style={{ height: "calc(100dvh - 133px - 180px - 20px)" }}
        className="flex flex-col px-2 hideSourceScrollBar overflow-y-scroll text-sm "
      >
        {findOverBookData?.wordOccurrencesOverBook &&
          Object?.keys(findOverBookData?.wordOccurrencesOverBook).map(
            (chapterKey) => {
              return (
                // <div className="flex flex-col  gap-2">
                //   <h1>Chapter: {chapterKey}</h1>
                //   <div className="flex flex-col px-4 gap-2">
                //     { Number(chapterKey)===occurrencesListData?.[0]?.chapterNo ?
                //     occurrencesListData?.[0]?.iterationList?.map((iteration)=>{
                //         return <div className="text-black text-sm">{iteration}</div>
                //     }): Array.from(
                //       { length: findOverBookData[chapterKey] },
                //       (_, i) => (
                //         <div
                //           onClick={() => {
                //             handleRedirection(i + 1, Number(chapterKey));
                //           }}
                //           className="cursor-pointer"
                //           key={i}
                //         >
                //           Occurance: {i + 1}/{findGlossaryData?.totalOccurances}
                //         </div>
                //       )
                //     )}
                //   </div>
                // </div>
                <Accordion
                  expanded={expanded}
                  onChange={handleChange}
                  sx={{
                    borderBottomLeftRadius: "0",
                    borderBottomLeftRadius: "0",
                    "&:last-of-type": {
                      borderRadius: "0px",
                    },
                    "&::before": {
                      height: 0, // Removes the height
                    },
                    boxShadow: "none",
                    // borderBottom: "1px solid #BFBFBF",
                    "& .MuiAccordion-region": { height: "100%" },
                    "& .MuiAccordionSummary-root": {
                      // Targeting the accordion summary
                      "&.Mui-expanded": {
                        // Adjust min-height when expanded
                        minHeight: "auto",
                      },
                    },

                    "& .MuiPaper-root": {
                      // Targeting the accordion summary
                      "&.Mui-expanded": {
                        // Adjust min-height when expanded
                        margin: "0px",
                      },
                    },
                  }}
                >
                  <AccordionSummary
                    expandIcon={<GlossaryViewIcon />}
                    aria-controls="panel1-content"
                    id="panel1-header"
                    sx={{
                      display: "flex",
                      borderBottomLeftRadius: "0",
                      borderBottomLeftRadius: "0",
                      paddingBottom: "0",
                      minHeight: "30px",
                      height: "45px",
                      paddingLeft: "5px",
                    }}
                  >
                    <div className="flex items-center gap-2 w-full">
                      {getChapterApplicationStatus(chapterKey) ===
                        "Pending" && (
                        <div
                          style={{ border: "5.333px solid #FFFAEB" }}
                          className="w-8 h-8 p-2 flex items-center justify-center bg-[#FEF0C7] rounded-[18.667px]"
                        >
                          <PendingOutline className="shrink-0" />
                        </div>
                      )}
                      {getChapterApplicationStatus(chapterKey) ===
                        "Applied" && (
                        <div
                          style={{ border: "5.333px solid #ECFDF3" }}
                          className="w-8 h-8 p-2 flex items-center justify-center bg-[#D1FADF] rounded-[18.667px]"
                        >
                          <CheckCircle className="shrink-0" />
                        </div>
                      )}
                      <div className="flex justify-between  w-full items-center ">
                        <span className="text-sm font-[500] w-[50%] text-[#1E1C80]">
                          CHAPTER {chapterKey}
                        </span>

                        <span className="text-[12px]  text-[#000000]">
                          {
                            findOverBookData?.wordOccurrencesOverBook?.[
                              chapterKey
                            ]
                          }{" "}
                          occurrences
                        </span>
                      </div>
                    </div>
                  </AccordionSummary>
                  <AccordionDetails
                    sx={{
                      padding: "0",
                      border: "none",
                      boxShadow: "none",
                      borderBottomLeftRadius: "0",
                      borderBottomLeftRadius: "0",
                      height: "100%",
                    }}
                  >
                    <div className="flex items-center px-2 h-full gap-2">
                      {(getChapterApplicationStatus(chapterKey) === "Applied" ||
                        getChapterApplicationStatus(chapterKey) ===
                          "Pending") && (
                        <div
                          style={{ borderLeft: "1px dashed #E3E3E3" }}
                          className="h-full self-stretch w-4 ml-[14px]"
                        ></div>
                      )}
                      <div className="flex flex-col w-full  pt-2  gap-4">
                        {occurrencesListData[chapterKey]?.length > 0
                           ? occurrencesListData[chapterKey]?.map(
                              (iteration, i) => {
                                return glossaryApplicationStatus[chapterKey][i+1] === "dummyStatus" ? (
                                  <div className="p-[5px] bg-[#F5F7FF] rounded flex gap-3 flex-col ">
                                    <div
                                      onClick={() =>
                                        handleRedirection(
                                          i + 1,
                                          Number(chapterKey)
                                        )
                                      }
                                      className="flex flex-col cursor-pointer px-2 gap-2"
                                    >
                                      <span className="text-[#475467] text-[12px]">
                                        Occurance: getOccuranceNo(chapterKey,{i + 1})/
                                        {findGlossaryData?.totalOccurances}
                                      </span>
                                      <div
                                        className=""
                                        dangerouslySetInnerHTML={{
                                          __html: iteration,
                                        }}
                                      />
                                    </div>
                                    <div
                                      style={{
                                        boxShadow:
                                          "0px 2px 10px 0px rgba(0, 0, 0, 0.04), 2px 4px 4px 0px rgba(0, 0, 0, 0.00)",
                                        borderRadius: "4px 4px 0px 0px",
                                      }}
                                      className="flex gap-2 flex-col rounded-xl py-4 px-3 bg-white"
                                    >
                                      <div
                                        style={{
                                          borderBottom: "1px solid #E3E3E3",
                                        }}
                                        className="px-2 pb-2 w-full"
                                        dangerouslySetInnerHTML={{
                                          __html: `<span class="add">Elizabeth</span> Snow had de late dienst in de kleine`,
                                        }}
                                      />
                                      <div className="flex justify-end">
                                        <div className="flex items-center gap-2 cursor-pointer">
                                          {" "}
                                          <EditPencil />{" "}
                                          <span className="font-[500] text-[12px] text-[#3B37FF]">
                                            Edit Translation
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                ) : (
                                  <div
                                    onClick={() => {
                                      handleRedirection(
                                        i + 1,
                                        Number(chapterKey)
                                      );
                                    }}
                                    key={i}
                                    style={{ border: "1px solid #DFDFDF" }}
                                    className="text-black flex gap-2 flex-col cursor-pointer p-3 rounded text-sm"
                                  >
                                    <span className="text-[#475467] text-[12px]">
                                      Occurance: {getOccuranceNo(chapterKey,i + 1)}/
                                      {findGlossaryData?.totalOccurances}
                                    </span>
                                    <div
                                      dangerouslySetInnerHTML={{
                                        __html: iteration,
                                      }}
                                    />
                                  </div>
                                );
                              }
                            )
                          : Array?.from(
                              {
                                length:
                                  findOverBookData?.wordOccurrencesOverBook?.[
                                    chapterKey
                                  ],
                              },
                              (_, i) => {
                                return (
                                  <div
                                    onClick={() => {
                                      handleRedirection(
                                        i + 1,
                                        Number(chapterKey)
                                      );
                                    }}
                                    key={i}
                                    style={{ border: "1px solid #DFDFDF" }}
                                    className="text-black p-3 cursor-pointer rounded text-sm"
                                  >
                                    Occurance: {i + 1}/
                                    {findGlossaryData?.totalOccurances}
                                  </div>
                                );
                              }
                            )}
                      </div>
                    </div>
                  </AccordionDetails>
                </Accordion>
              );
            }
          )}
      </div>
    </div>
  );
}

export default GlossaryApplicationStatus;
